import React from "react";
import ConnectionLinks from "../ConnectionLinks/ConnectionLinks";

import "./Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="footer__container">
        <p>
          <ConnectionLinks colorModification="lightgray" />
        </p>
        <p className="footer__text">
          Copyright - 2021 Attila Gőz, All Right Reserved
        </p>
        <p className="footer__text image_text">
          {/* Images from unsplash.com (Greg Rakozy, NASA, Pawel Nolbert, Jonathan Pie, Daiwei Lu, Andreas Brücker, Ryan Hutton) */}
          Images from{" "}
          <a href="https://unsplash.com" className="text_links">
            unsplash.com
          </a>{" "}
          (
          <a
            href="https://unsplash.com/@grakozy?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            className="text_links"
          >
            Greg Rakozy
          </a>
          {", "}
          <a
            href="https://unsplash.com/@hellocolor?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            className="text_links"
          >
            Pawel Nolbert
          </a>
          {", "}
          <a
            href="https://unsplash.com/@nasa?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            className="text_links"
          >
            NASA
          </a>
          {", "}
          <a
            href="https://unsplash.com/@r3dmax?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            className="text_links"
          >
            Jonathan Pie
          </a>
          {", "}
          <a
            href="https://unsplash.com/@daiwei?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            className="text_links"
          >
            Daiwei Lu
          </a>
          {", "}
          <a
            href="https://unsplash.com/@andreasbruecker?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            className="text_links"
          >
            Andreas Brücker
          </a>
          {", "}
          <a
            href="https://unsplash.com/@ryan_hutton_?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            className="text_links"
          >
            Ryan Hutton
          </a>
          )
        </p>
      </div>
    </div>
  );
}

export default Footer;

// https://unsplash.com/@grakozy?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText
// https://unsplash.com/@hellocolor?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText
// https://unsplash.com/@nasa?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText
// https://unsplash.com/@r3dmax?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText
// https://unsplash.com/@daiwei?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText
// https://unsplash.com/@andreasbruecker?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText
// https://unsplash.com/@ryan_hutton_?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText
