import React from "react";

import "./MenuButton.css";

function MenuButton({ buttonId, label }) {
  return (
    <button
      id={`${buttonId}-button`}
      className="menu_button"
      onClick={() => {
        const yOffset = buttonId === "home" ? -80 : -50;
        const element = document.getElementById(buttonId);
        const yPosition =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: yPosition, behavior: "smooth" });
      }}
    >
      {label}
    </button>
  );
}

export default MenuButton;
