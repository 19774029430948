import React, { useEffect, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";

import "./Header.css";

import logoImage from "../../assets/coding_icon.png";
import MobileMenu from "../MobileMenu/MobileMenu";

function Header({ homeRef, aboutRef, projectsRef, skillsRef, contactRef }) {
  const [showMenu, setShowMenu] = useState(true);
  const [menuBackgroundColor, setMenuBackgroundColor] = useState("#14151690");
  const [menuButtonColor, setMenuButtonColor] = useState("#f3f3f3");
  const [menuHeight, setMenuHeight] = useState("80px");
  const [onTop, setOnTop] = useState(true);

  useEffect(() => {
    let observer;
    if (
      homeRef.current &&
      aboutRef.current &&
      projectsRef.current &&
      skillsRef.current &&
      contactRef.current
    ) {
      const options = {
        threshold: 0.2,
      };
      observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          const navElement = document.querySelector(
            `button[id="${entry.target.id}-button"]`
          );

          if (entry.isIntersecting) {
            if (!navElement.classList.contains("button_active")) {
              navElement.classList.add("button_active");
            }
          } else if (navElement.classList.contains("button_active")) {
            navElement.classList.remove("button_active");
          }
        });
      }, options);
      observer.observe(homeRef.current);
      observer.observe(aboutRef.current);
      observer.observe(projectsRef.current);
      observer.observe(skillsRef.current);
      observer.observe(contactRef.current);
    }
    return () => observer.disconnect();
  }, [homeRef, aboutRef, projectsRef, skillsRef, contactRef]);

  useEffect(() => {
    const colorCheckOnMenu = () => {
      setShowMenu(true);
      if (onTop) {
        buttonRecolor("#f3f3f3");
      }
    };
    const screenSizeCheck = () => {
      window.innerWidth > 850 ? colorCheckOnMenu() : setShowMenu(false);
    };

    screenSizeCheck();

    window.addEventListener("resize", () => screenSizeCheck());

    return () => {
      window.removeEventListener("resize", () => screenSizeCheck());
    };
  }, []);

  const headerButtons = [
    {
      label: "Home",
      href: "home",
    },
    {
      label: "About",
      href: "about",
    },
    {
      label: "Projects",
      href: "projects",
    },
    {
      label: "Skills",
      href: "skills",
    },
    {
      label: "Contact",
      href: "contact",
    },
  ];
  const menuButtonElements = document.getElementsByClassName("menu_button");
  const buttonRecolor = (color) => {
    setMenuButtonColor(color);
    for (let i = 0; i < headerButtons.length; i++) {
      menuButtonElements[i].style.color = color;
    }
  };

  useEffect(() => {
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      const headerElement = document.getElementById("header");
      let observerHeader = new IntersectionObserver((entries) => {
        if (entries[0].boundingClientRect.y > 0) {
          headerElement.style.backgroundColor = "#14151690";
          headerElement.style.height = "80px";
          setMenuBackgroundColor("#14151690");
          setMenuHeight("80px");
          setOnTop(true);
          buttonRecolor("#f3f3f3");
        } else {
          headerElement.style.backgroundColor = "#ccc";
          headerElement.style.height = "50px";
          setMenuBackgroundColor("#ccc");
          setMenuHeight("50px");
          setOnTop(false);
          buttonRecolor("#222222");
        }
      });

      observerHeader.observe(document.querySelector("#anchor_header"));
    }
  }, []);

  return (
    <div id="header">
      <div className="header__wrapper">
        <img
          className="header__logo"
          onClick={() => {
            const yOffset = -80;
            const element = document.getElementById("home");
            const yPosition =
              element.getBoundingClientRect().top +
              window.pageYOffset +
              yOffset;
            window.scrollTo({ top: yPosition, behavior: "smooth" });
          }}
          src={logoImage}
          alt="logo"
        />
        {showMenu ? (
          <div className="header__buttons">
            {headerButtons.map(({ href, label }) => {
              return <MenuButton buttonId={href} label={label} key={href} />;
            })}
          </div>
        ) : (
          <MobileMenu
            menuBackgroundColor={menuBackgroundColor}
            menuButtonColor={menuButtonColor}
            headerButtons={headerButtons}
            menuHeight={menuHeight}
          />
        )}
      </div>
    </div>
  );
}

export default Header;
