import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt, faMobileAlt, faGlobe } from "@fortawesome/free-solid-svg-icons";

import "./ContactInfo.css";

function ContactInfo() {
  return (
    <div className="contactInfo">
      <div className="contactInfo__title">Contact Info</div>

      <div className="contactInfo__subtitle">
        Feel free to contact me for career prospects, business services, and
        other professional inquiries!
      </div>

      <div className="contactInfo__infoBar">
        <div className="contactInfo__icon">
          <FontAwesomeIcon icon={faGlobe} />
        </div>
        <div className="contactInfo__infos">
          <div className="contactInfo__infoTitle">Location</div>
          <div className="contactInfo__info">
            Dielsdorf, Kanton Zürich, Switzerland
          </div>
        </div>
      </div>

      <div className="contactInfo__infoBar">
        <div className="contactInfo__icon">
          <FontAwesomeIcon icon={faMobileAlt} />
        </div>
        <div className="contactInfo__infos">
          <div className="contactInfo__infoTitle">Call Me At</div>
          <div className="contactInfo__info">
            <a href="tel:+41-79-786-7940" className="text_links">
              +41 79 786 79 40
            </a>
          </div>
        </div>
      </div>

      <div className="contactInfo__infoBar">
        <div className="contactInfo__icon">
          <FontAwesomeIcon icon={faAt} />
        </div>
        <div className="contactInfo__infos">
          <div className="contactInfo__infoTitle">E-mail Me At</div>
          <div className="contactInfo__info">
            <a href="mailto:goz.attila.1977@gmail.com" className="text_links">
              goz.attila.1977@gmail.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactInfo;
