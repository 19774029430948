import React from "react";

import logoImage from "../../assets/coding_icon.png";

import "./Spinner.css";

function Spinner() {
  return (
    <div className="spinner">
      {" "}
      <img className="spinner__logo" src={logoImage} alt="logo" />
    </div>
  );
}

export default Spinner;
