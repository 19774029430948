import React from "react";

import ProjectCard from "../ProjectCard/ProjectCard";

import { projectList } from "../baseData/baseData";

import "./Projects.css";

function Projects({ projectsRef }) {
  return (
    <section id="projects" ref={projectsRef}>
      <div className="projects__title">Projects</div>
      <div className="projects__list">
        {/* {projectList.map(({ name, techs, links }) => { */}
        {projectList.map(
          ({ id, name, image, text, tech, appLink, codeLink, archived }) => {
            if (!archived) {
              return (
                <ProjectCard
                  key={id}
                  name={name}
                  image={image}
                  text={text}
                  tech={tech}
                  appLink={appLink}
                  codeLink={codeLink}
                />
              );
            } else {
              return null;
            }
          }
        )}
      </div>
      <div id="anchor_projects"></div>
    </section>
  );
}

export default Projects;
