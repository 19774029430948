import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import "./ContactForm.css";

function ContactForm() {
  const newForm = (
    <form
      name="contact"
      method="POST"
      action="/contact"
      className="contactForm"
    >
      <input type="hidden" name="form-name" value="contact" />
      <div className="contactForm__title title_style">Leave a Message!</div>

      <div className="contactForm__inputs">
        <div className="contactForm__inputBlock">
          <label className="contactForm__label" htmlFor="name"></label>
          <input
            className="contactForm__input"
            type="text"
            name="name"
            id="name"
            placeholder="Your Name:"
            required
          />
        </div>

        <div className="contactForm__inputBlock">
          <label className="contactForm__label" htmlFor="email"></label>
          <input
            className="contactForm__input"
            type="email"
            name="email"
            id="email"
            placeholder="Email:"
            required
          />
        </div>
      </div>

      <div className="contactForm__messageBlock">
        <label className="contactForm__label" htmlFor="message"></label>
        <textarea
          className="contactForm__textarea"
          name="message"
          id="message"
          placeholder="Your Message:"
          required
        />
      </div>

      <div className="contactForm__buttonBlock">
        <button className="contactForm__button" type="submit">
          <FontAwesomeIcon
            icon={faPaperPlane}
            className="contactForm__buttonIcon"
          />
          Send Message
        </button>
      </div>
    </form>
  );

  return <>{newForm}</>;
  // <>
  //   <form
  //     name="contact"
  //     method="POST"
  //     action="/contact"
  //     className="contactForm"
  //   >
  //     <input type="hidden" name="form-name" value="contact" />
  //     <div className="contactForm__title">
  //       <span className="title_style">Leave a Message!</span>
  //     </div>

  //     <div className="contactForm__inputs">
  //       {/* <div> */}
  //       <label className="contactForm__label" htmlFor="name">
  //         {/* Your Name: */}
  //       </label>
  //       <input
  //         className="contactForm__input"
  //         type="text"
  //         name="name"
  //         id="name"
  //         placeholder="Your Name:"
  //         required
  //       />
  //       {/* </div> */}

  //       {/* <div> */}
  //       <label className="contactForm__label" htmlFor="email">
  //         {/* Email: */}
  //       </label>
  //       <input
  //         className="contactForm__input"
  //         type="email"
  //         name="email"
  //         id="email"
  //         placeholder="Email:"
  //         required
  //       />
  //       {/* </div> */}
  //     </div>

  //     {/* <div> */}
  //     <label className="contactForm__label" htmlFor="message">
  //       {/* Your Message: */}
  //     </label>
  //     <div className="contactForm__inputs">
  //       <textarea
  //         className="contactForm__textarea"
  //         name="message"
  //         id="message"
  //         placeholder="Your Message:"
  //         required
  //       />
  //     </div>
  //     {/* </div> */}
  //     <div className="contactForm__inputs">
  //       <button className="contactForm__button" type="submit">
  //         {/* {status} */}
  //         <FontAwesomeIcon
  //           icon={faPaperPlane}
  //           className="contactForm__buttonIcon"
  //         />
  //         Send Message
  //       </button>
  //     </div>
  //   </form>
  // </>
}

export default ContactForm;
