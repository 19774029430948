import React, { useEffect, useState } from "react";

import "./App.css";

import Pages from "./components/Pages/Pages";
import Spinner from "./components/Spinner/Spinner";

import { imageList } from "./components/baseData/baseData";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    cacheImages(imageList);
  }, []);

  const cacheImages = async (imageArray) => {
    const promises = await imageArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });

    await Promise.all(promises);
    setIsLoading(false);
  };

  return (
    <div className="app">
      {isLoading ? (
        <div className="app__loading">
          <Spinner />
        </div>
      ) : (
        <Pages />
      )}
    </div>
  );
}

export default App;
