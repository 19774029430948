import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

import GA_Resume from "../../assets/resume/Goz_Attila_CV.pdf";

import "./ResumeButton.css";

function ResumeButton() {
  return (
    <a href={GA_Resume} download="Goz_Attila_CV" className="resume__button">
      <FontAwesomeIcon icon={faFileDownload} className="resume__icon" />
      Resume
    </a>
  );
}

export default ResumeButton;
