import React from "react";

import NamedIcon from "../NamedIcon/NamedIcon";

import {
  skillList,
  orderedSkills,
  underDevelopment,
} from "../baseData/baseData";

import "./Skills.css";

function Skills({ skillsRef }) {
  return (
    <section id="skills" ref={skillsRef}>
      <div className="skills__title">Skills</div>
      <div className="skills__subTitle">
        Languages and Technologies that I have learned and applied to my project
      </div>
      <div className="skills__list">
        {orderedSkills.map((value) => {
          const skillName = skillList[value]["name"];
          const skillImage = skillList[value]["image"];
          return (
            <div className="skills__element" key={`SkillList-${skillName}`}>
              <NamedIcon name={skillName} image={skillImage} size="big" />
            </div>
          );
        })}
      </div>
      <div className="skills__subTitle">
        Languages and Technologies under developement
      </div>
      <div className="skills__list">
        {underDevelopment.map((value) => {
          const skillName = skillList[value]["name"];
          const skillImage = skillList[value]["image"];
          return (
            <div className="skills__element" key={`SkillList-${skillName}`}>
              <NamedIcon name={skillName} image={skillImage} size="big" />
            </div>
          );
        })}
      </div>
      <div id="anchor_skills"></div>
    </section>
  );
}

export default Skills;
