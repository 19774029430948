import React from "react";

import "./NamedIcon.css";

function NamedIcon({ name, image, size }) {
  let imageSize = "40px";
  let nameStyle = { fontSize: "14px", marginTop: "2px" };
  if (size === "medium") {
    nameStyle = { fontSize: "16px", marginTop: "4px" };
    imageSize = "60px";
  }
  if (size === "big") {
    nameStyle = { fontSize: "18px", marginTop: "12px" };
    imageSize = "80px";
  }
  return (
    <div className="namedIcon">
      <img
        src={image}
        className="namedIcon__icon"
        alt={`${name} icon`}
        style={{ height: imageSize }}
      />
      <div className="namedIcon__name" style={nameStyle}>
        {name}
      </div>
    </div>
  );
}

export default NamedIcon;
