import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import MenuButton from "../MenuButton/MenuButton";

import "./MobileMenu.css";

function MobileMenu({
  menuBackgroundColor,
  headerButtons,
  menuHeight,
  menuButtonColor,
}) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const menuListElement = document.getElementById("MobileMenu__MenuWrap");
    menuListElement.className = isOpen
      ? "MobileMenu__MenuWrap"
      : "MobileMenu__MenuWrap hide";
  }, [isOpen]);

  return (
    <div className="MobileMenu">
      <FontAwesomeIcon
        icon={faBars}
        className="MobileMenu__MenuIcon"
        onClick={() => setIsOpen(true)}
        style={
          isOpen
            ? { opacity: 0, transition: "opacity 0.8s" }
            : { opacity: 1, transition: "opacity 0.8s" }
        }
      />
      <div
        id="MobileMenu__MenuWrap"
        className="MobileMenu__MenuWrap hide"
        onClick={() => setIsOpen(false)}
        style={{ backgroundColor: menuBackgroundColor }}
      >
        <div
          className="MobileMenu__close"
          style={{ height: menuHeight, transition: "height ease-in-out 0.4s" }}
        >
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="MobileMenu__closeIcon"
            style={{ color: menuButtonColor }}
            onClick={() => setIsOpen(false)}
          />
        </div>
        <div className="MobileMenu__ButtonList">
          {headerButtons.map(({ href, label }) => {
            return (
              <MenuButton
                buttonId={href}
                label={label}
                key={href}
                //   activeButton={activeButton}
              />
            );
          })}
        </div>
      </div>
      {isOpen ? (
        <div
          className="MobileMenu__Overlay"
          onClick={() => setIsOpen(false)}
        ></div>
      ) : null}
    </div>
  );
}

export default MobileMenu;
