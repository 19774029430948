import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

import "./ConnectionLinks.css";

function ConnectionLinks({ colorModification = "" }) {
  const classWithMods =
    colorModification === "" ? "links" : `links ${colorModification}`;

  return (
    <>
      <a href="https://www.linkedin.com/in/ati-goz/">
        <FontAwesomeIcon className={classWithMods} icon={faLinkedinIn} />
      </a>
      <a href="http://github.com/GozAttila">
        <FontAwesomeIcon className={classWithMods} icon={faGithub} />
      </a>
    </>
  );
}

export default ConnectionLinks;
