import React from "react";
import ContactForm from "../ContactForm/ContactForm";
import ContactInfo from "../ContactInfo/ContactInfo";
import Footer from "../Footer/Footer";

import "./Contact.css";

function Contact({ contactRef }) {
  return (
    <section id="contact" ref={contactRef}>
      <div className="contact__container">
        <ContactForm />
        <ContactInfo />
      </div>
      <Footer />
      <div id="anchor_contact"></div>
    </section>
  );
}

export default Contact;
