import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt, faMobileAlt } from "@fortawesome/free-solid-svg-icons";

import ConnectionLinks from "../ConnectionLinks/ConnectionLinks";
import ResumeButton from "../ResumeButton/ResumeButton";

import "./About.css";

import portreImage from "../../assets/GA_Portre_sm.jpg";
import cPlusFourImage from "../../assets/cp4.png";
import Goz_Attila_CV_Engineer from "../../assets/resume/Goz_Attila_CV_Engineer.pdf";

function About({ aboutRef }) {
  const [smallView, setSmallView] = useState(false);

  useEffect(() => {
    const screenCheck = () => {
      window.innerWidth < 993 ? setSmallView(true) : setSmallView(false);
    };

    screenCheck();

    window.addEventListener("resize", () => screenCheck());
  }, []);

  const aboutConnections = (
    <div className="about__connections">
      <div className="about__data">
        <FontAwesomeIcon icon={faAt} className="about__icons" />
        <a href="mailto:goz.attila.1977@gmail.com" className="text_links">
          goz.attila.1977@gmail.com
        </a>
      </div>
      <div className="about__data">
        <FontAwesomeIcon icon={faMobileAlt} className="about__icons icon_mod" />
        <a href="tel:+41-79-786-7940" className="text_links">
          +41 79 786 79 40
        </a>
      </div>
      <div className="about__links">
        <ConnectionLinks colorModification="gray" />
      </div>
    </div>
  );

  return (
    <section id="about" className="about" ref={aboutRef}>
      <div className="about__wrapper">
        <div className="about__info">
          <img className="about__image" src={portreImage} alt="portre" />
          {smallView ? null : aboutConnections}
        </div>

        <div className="about__description">
          <h2 className="about__welcome">
            Hello! I am Attila,
            <br />a Full Stack Developer from Switzerland.
          </h2>
          <p>
            When I was eight, I wrote programs on my{" "}
            <a
              href="https://en.wikipedia.org/wiki/Commodore_Plus/4"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="about__commodoreLogo"
                src={cPlusFourImage}
                alt="Commodore Plus 4 logo"
              />
            </a>
            , and now I am coding. Again. The End
          </p>
          <p>
            Too short? Maybe. But that's not meaning this can't be true. I grew
            up in an era when there was no internet to learn and no teachers for
            coding. But I wanted to code, so I learnt everything from the book
            that they gave me with the computer. Well, this is how my coding
            journey started.
          </p>
          <p>
            Imagination, innovation, creation and loving technology run deep in
            my family. So it's no surprise, that I became a Mechanical Engineer.
            Over the years, I was always close to technology. I was 16 when I
            made my first custom-build PC. At 20, I created a local network of 6
            PCs from zero. As an engineer, I automatized processes, modified CNC
            and robot-programs for better and faster working, optimized not only
            production lines but also production areas. I planned and built
            hydraulic and pneumatic systems. Imagine and turn into real
            production systems for brand new products. So, I have busy and
            delightful years behind me. I collected a vast amount of experience
            on both the technology and the human side. But I missed one thing. I
            missed the enjoyment that I felt when I was only eight, and I sat in
            front of my computer and wrote line after line.
            <br />
            If you want to know a little more about my past, download and check
            my{" "}
            <a
              href={Goz_Attila_CV_Engineer}
              download="Goz_Attila_CV_Engineer"
              className="text_links"
            >
              engineer resume
            </a>
            .
          </p>

          <p>
            I rejoined coding with Unity training, but that was just the
            beginning. After I moved to Switzerland, there were no distractions
            and no excuses. Through{" "}
            <a
              className="text_links"
              href="https://propulsion.academy/"
              target="_blank"
              rel="noreferrer"
            >
              Propulsion Academy
            </a>
            , I have developed a strong understanding of Javascript (ES5 & ES6),
            React, Redux, Python, Django, PostgreSQL, HTML5, CSS3 and a little
            DevOps with Gitlab CI/CD and Docker. Now I can turn my ideas into
            code and apps. I enjoy learning, so this field is neverending fun,
            as it is constantly growing, and I want to grow with it.
          </p>
          <p>
            Feel free to connect with me! Let's talk about development, tech,
            video and/or board games, books, or how I can help you and your team
            or business! I'd love to get to know more developers and expand my
            network. Or just simply talk, next to a coffee or a beer, choose
            your poison.
          </p>
          <div className="about__resume">
            {smallView ? aboutConnections : null}
            <ResumeButton />
          </div>
        </div>
      </div>
      <div id="anchor_about"></div>
    </section>
  );
}

export default About;
