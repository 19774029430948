import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./LinkButton.css";

function LinkButton({ icon, name, link }) {
  return (
    <a href={link} className="linkButton">
      <FontAwesomeIcon icon={icon} className="linkButton__icon" />
      {name}
    </a>
  );
}

export default LinkButton;
