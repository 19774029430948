// only for imagecache list

import backgroundImage1 from "../../assets/backgrounds/img1.jpg";
import backgroundImage2 from "../../assets/backgrounds/img2.jpg";
import backgroundImage3 from "../../assets/backgrounds/img3.jpg";
import backgroundImage5 from "../../assets/backgrounds/img5.jpg";
import backgroundImage6 from "../../assets/backgrounds/img6.jpg";
import backgroundImage7 from "../../assets/backgrounds/img7.jpg";
import backgroundImage8 from "../../assets/backgrounds/img8.jpg";
import portreImage from "../../assets/GA_Portre_sm.jpg";

// for imagecache list, portfolio ans skill

import amazonImage from "../../assets/portfolio/amazon.png";
import messengerImage from "../../assets/portfolio/messenger.png";
import twitterImage from "../../assets/portfolio/twitter.png";
import covidImage from "../../assets/portfolio/covid.png";
import moreImage from "../../assets/portfolio/more.png";
import personalImage from "../../assets/portfolio/personal.png";

import styledComponentsImage from "../../assets/Icons/styled_components.png";
import DRFImage from "../../assets/Icons/DRF.png";
import css3Image from "../../assets/Icons/css3-original.svg";
import djangoImage from "../../assets/Icons/django-original.svg";
import dockerImage from "../../assets/Icons/docker-original.svg";
import firebaseImage from "../../assets/Icons/firebase-plain.svg";
import githubImage from "../../assets/Icons/github-original.svg";
import gitlabImage from "../../assets/Icons/gitlab-original.svg";
import html5Image from "../../assets/Icons/html5-original.svg";
import javaScriptImage from "../../assets/Icons/javascript-original.svg";
import nodejsImage from "../../assets/Icons/nodejs-original.svg";
import npmImage from "../../assets/Icons/npm-original-wordmark.svg";
import postgresqlImage from "../../assets/Icons/postgresql-original.svg";
import pycharmImage from "../../assets/Icons/icon-pycharm.svg";
import pythonImage from "../../assets/Icons/python-original.svg";
import reactImage from "../../assets/Icons/react-original.svg";
import reduxImage from "../../assets/Icons/redux-original.svg";
import visualStudioCodeImage from "../../assets/Icons/visualstudio-plain.svg";
import webStormImage from "../../assets/Icons/icon-webstorm.svg";
import typeScriptImage from "../../assets/Icons/typescript-original.svg";

export const imageList = [
  backgroundImage1,
  backgroundImage2,
  backgroundImage3,
  backgroundImage5,
  backgroundImage6,
  backgroundImage7,
  backgroundImage8,
  portreImage,
  amazonImage,
  messengerImage,
  twitterImage,
  covidImage,
  moreImage,
  personalImage,
  styledComponentsImage,
  DRFImage,
  css3Image,
  djangoImage,
  dockerImage,
  firebaseImage,
  githubImage,
  gitlabImage,
  html5Image,
  javaScriptImage,
  nodejsImage,
  npmImage,
  postgresqlImage,
  pycharmImage,
  pythonImage,
  reactImage,
  reduxImage,
  visualStudioCodeImage,
  webStormImage,
  typeScriptImage,
];

export const projectList = [
  {
    id: 1,
    image: personalImage,
    name: "This personal page",
    text: "My own personal page",
    tech: [
      { image: html5Image, name: "HTML5" },
      { image: css3Image, name: "CSS3" },
      { image: javaScriptImage, name: "JS" },
      { image: reactImage, name: "React" },
    ],
    appLink: "https://gozattila.dev/",
    codeLink: "",
    archived: false,
  },
  {
    id: 2,
    image: covidImage,
    name: "Covid-19 Tracker",
    text: "Covid-19 pandemic visualization app",
    tech: [
      { image: html5Image, name: "HTML5" },
      { image: css3Image, name: "CSS3" },
      { image: javaScriptImage, name: "JS" },
      { image: reactImage, name: "React" },
    ],
    appLink: "https://covid-19-tracker-by-ga.netlify.app/",
    codeLink: "https://github.com/GozAttila/Covid-19-Tracker",
    archived: false,
  },
  {
    id: 3,
    image: amazonImage,
    name: "Amazon clone",
    text: "Amazon frontend clone with working basket and payment",
    tech: [
      { image: html5Image, name: "HTML5" },
      { image: css3Image, name: "CSS3" },
      { image: javaScriptImage, name: "JS" },
      { image: reactImage, name: "React" },
      { image: firebaseImage, name: "Firebase" },
    ],
    appLink: "https://clone-8e70a.web.app/",
    codeLink: "https://github.com/GozAttila/Amazon-clone",
    archived: false,
  },
  {
    id: 4,
    image: messengerImage,
    name: "All-In chat app",
    text: "A Messenger clone with register, login and chat function",
    tech: [
      { image: html5Image, name: "HTML5" },
      { image: css3Image, name: "CSS3" },
      { image: javaScriptImage, name: "JS" },
      { image: reactImage, name: "React" },
      { image: firebaseImage, name: "Firebase" },
    ],
    appLink: "https://facebook-messenger-clone-9bc9a.web.app/",
    codeLink: "https://github.com/GozAttila/Facebook-messenger-clone",
    archived: false,
  },
  {
    id: 5,
    image: twitterImage,
    name: "Twitter",
    text: "Twitter frontend clone",
    tech: [
      { image: html5Image, name: "HTML5" },
      { image: css3Image, name: "CSS3" },
      { image: javaScriptImage, name: "JS" },
      { image: reactImage, name: "React" },
      { image: firebaseImage, name: "Firebase" },
    ],
    appLink: "https://twitter-clone-928a2.web.app/",
    codeLink: "https://github.com/GozAttila/Twitter-clone",
    archived: false,
  },
  {
    id: 6,
    image: moreImage,
    name: "Next...",
    text: "Check later, what will be here",
    tech: [],
    appLink: "",
    codeLink: "",
    archived: false,
  },
];

export const skillList = [
  {
    name: "Styled Components",
    image: styledComponentsImage,
  },
  {
    name: "django REST framework",
    image: DRFImage,
  },
  {
    name: "CSS 3",
    image: css3Image,
  },
  {
    name: "django",
    image: djangoImage,
  },
  {
    name: "Docker",
    image: dockerImage,
  },
  {
    name: "Firebase",
    image: firebaseImage,
  },
  {
    name: "GitHub",
    image: githubImage,
  },
  {
    name: "GitLab",
    image: gitlabImage,
  },
  {
    name: "HTML 5",
    image: html5Image,
  },
  {
    name: "JavaScript (ES5/ES6)",
    image: javaScriptImage,
  },
  {
    name: "Node.js",
    image: nodejsImage,
  },
  {
    name: "npm",
    image: npmImage,
  },
  {
    name: "PostgreSQL",
    image: postgresqlImage,
  },
  {
    name: "PyCharm",
    image: pycharmImage,
  },
  {
    name: "Python",
    image: pythonImage,
  },
  {
    name: "React.js",
    image: reactImage,
  },
  {
    name: "React Native",
    image: reactImage,
  },
  {
    name: "Redux",
    image: reduxImage,
  },
  {
    name: "Visual Studio Code",
    image: visualStudioCodeImage,
  },
  {
    name: "WebStorm",
    image: webStormImage,
  },
  {
    name: "TypeScript",
    image: typeScriptImage,
  },
];

export const orderedSkills = [
  8,
  2,
  9,
  15,
  17,
  14,
  3,
  1,
  0,
  11,
  10,
  6,
  12,
  4,
  7,
  18,
  13,
  19,
];
export const underDevelopment = [5, 16, 20];
