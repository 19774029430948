import React, { useEffect } from "react";

import "./Home.css";

import ConnectionLinks from "../ConnectionLinks/ConnectionLinks";

import backgroundImage1 from "../../assets/backgrounds/img1.jpg";
import backgroundImage2 from "../../assets/backgrounds/img2.jpg";
import backgroundImage3 from "../../assets/backgrounds/img3.jpg";
import backgroundImage5 from "../../assets/backgrounds/img5.jpg";
import backgroundImage6 from "../../assets/backgrounds/img6.jpg";
import backgroundImage7 from "../../assets/backgrounds/img7.jpg";
import backgroundImage8 from "../../assets/backgrounds/img8.jpg";

function Home({ homeRef }) {
  useEffect(() => {
    const backgroundImages = [
      backgroundImage1,
      backgroundImage2,
      backgroundImage3,
      backgroundImage5,
      backgroundImage6,
      backgroundImage7,
      backgroundImage8,
    ];
    let imagePosition = 0;
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      let observerBackground = new IntersectionObserver((entries) => {
        if (entries[0].boundingClientRect.y < 0) {
          const homeElement = document.getElementById("home");
          imagePosition =
            ++imagePosition >= backgroundImages.length ? 0 : imagePosition++;
          homeElement.style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${backgroundImages[imagePosition]})`;
        }
      });
      observerBackground.observe(document.querySelector("#anchor_background"));
    }
  }, []);

  return (
    <>
      <section id="home" ref={homeRef}>
        <div className="home__wrapper">
          <div className="home__name">Attila</div>
          <div className="home__name">Gőz</div>
          <div className="home__description">
            <span className="strong_text orange">Full Stack Developer</span> by
            Day
          </div>
          <div className="home__description">
            <span className="strong_text blue">Self-educator</span> by Night
          </div>
          <div className="home__description">
            <span className="strong_text green">Creative Engineer</span> for a
            lifetime
          </div>
          <div className="home__links">
            <ConnectionLinks />
          </div>
        </div>
        <div id="anchor_header"></div>
        <div id="anchor_home"></div>
        <div id="anchor_background"></div>
      </section>
    </>
  );
}

export default Home;
