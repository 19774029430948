import React from "react";
import { faCode, faDesktop } from "@fortawesome/free-solid-svg-icons";

import LinkButton from "../LinkButton/LinkButton";
import NamedIcon from "../NamedIcon/NamedIcon";

import "./ProjectCard.css";

function ProjectCard({ name, image, text, tech, appLink, codeLink }) {
  return (
    <div className="projectCard">
      <div className="projectCard__name">{name}</div>
      <div className="projectCard__screenshot">
        <div
          className="projectCard__image"
          style={{ backgroundImage: `url(${image})` }}
        ></div>
      </div>
      <div className="projectCard__tech">
        {tech.length === 0
          ? null
          : tech.map(({ image, name }) => {
              return (
                <NamedIcon
                  key={`ProjectCard-${name}`}
                  name={name}
                  image={image}
                  size="small"
                />
              );
            })}
      </div>
      <div className="projectCard__buttons">
        {appLink === "" ? null : (
          <LinkButton icon={faDesktop} name="Demo" link={appLink} />
        )}
        {codeLink === "" ? null : (
          <LinkButton icon={faCode} name="Code" link={codeLink} />
        )}
      </div>
    </div>
  );
}

export default ProjectCard;
